/* eslint-disable @typescript-eslint/camelcase */
import { BaseRatesEntities, RatesDetail } from "@/domain/entities/BaseRates";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import { container } from "tsyringe";
import { BaseRatesPresenter } from "../presenters/BaseRatesPresenter";
import { EditBaseRatesApiRequest } from "@/data/payload/api/BaseRatesApiRequest";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

export interface BaseRatesState {
  isLoading: boolean;
  isLoadingDetail: boolean;
  isLoadingVendor: boolean;
  baseRatesData: BaseRatesEntities;
  isError: boolean;
  isErrorEdit: boolean;
  isErrorCause: string;
  openModal: boolean;
  openModalSuccess: boolean;
}
@Module({ namespaced: true, dynamic: true, store, name: "baserates" })
class BaseRatesStore extends VuexModule implements BaseRatesState {
  public isLoading = false;
  public isLoadingDetail = false;
  public isLoadingVendor = false;
  public baseRatesData = new BaseRatesEntities(new Pagination(1, 10), []);
  public search = "";
  public type = "";
  public status = "";
  public isError = false;
  public isErrorEdit = false;
  public isErrorCause = "";
  public openModal = false;
  public openModalSuccess = false;
  public firstRequest = true;
  public ratesDetail = new RatesDetail(
    0,
    "",
    "",
    0,
    "",
    "",
    [],
    "",
    "",
    "",
    "",
    "",
    "", 
    ""
  );
  public isRatesStatus = false;
  public isCacheRatesStatus = false;
  public page = 1;
  public limit = 10;
  public openSuccess = false;

  @Action
  public getBaseRatesList(params: {
    search: string;
    status: string;
    page: number;
    limit: number;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(BaseRatesPresenter);
    presenter
      .getBaseRatesList(params.search, params.status, params.page, params.limit)
      .then((res: BaseRatesEntities) => {
        this.setBaseRatesList(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public fetchBaseRates() {
    this.setFirstRequest(false);
    this.getBaseRatesList({
      search: this.search,
      status: this.status,
      page: this.baseRatesData.pagination.page,
      limit: this.baseRatesData.pagination.limit
    });
  }

  @Action
  public initBaseRates() {
    this.setFirstPage();
    this.setFirstRequest(true);
    this.setStatus("");
    this.setSearch("");
    this.getBaseRatesList({
      search: "",
      status: "",
      page: this.baseRatesData.pagination.page,
      limit: this.baseRatesData.pagination.limit
    });
  }

  @Action
  public setFirstPage() {
    this.baseRatesData.pagination.page = 1;
  }

  @Action
  public selectStatusAction(params: { name: string; value: string }) {
    this.setStatus(params.value);
    this.setFirstPage();
    this.fetchBaseRates();
  }

  @Action
  public searchAction(value: string) {
    this.setSearch(value);
    this.setFirstPage();
    this.fetchBaseRates();
  }

  @Action
  public clear() {
    this.searchAction("");
  }

  @Mutation
  private setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  private setBaseRatesList(data: BaseRatesEntities) {
    this.baseRatesData = data;
  }

  @Mutation
  private setSearch(value: string) {
    this.search = value;
  }

  @Mutation
  private setStatus(value: string) {
    this.status = value;
  }

  @Mutation
  private setError(boolean: boolean) {
    this.isError = boolean;
  }

  @Mutation
  public setErrorEdit(boolean: boolean) {
    this.isErrorEdit = boolean;
  }

  @Mutation
  public setErrorCause(str: string) {
    this.isErrorCause = str;
  }

  @Mutation
  public setOpenModal(value: boolean) {
    this.openModal = value;
  }

  @Mutation
  public setOpenModalSuccess(value: boolean) {
    this.openModalSuccess = value;
  }

  @Mutation
  public setFirstRequest(value: boolean) {
    this.firstRequest = value;
  }

  @Mutation
  public setRatesDetail(data: RatesDetail) {
    this.ratesDetail = data;
  }

  @Mutation
  public async nextPage() {
    if (this.page >= 1) {
      this.page = Number(this.page) + 1;
    }
  }

  @Mutation
  public async prevPage() {
    this.page = Number(this.page) - 1;
  }

  @Mutation
  public async setPage(page: number) {
    this.page = page;
  }

  @Mutation
  public setRatesStatus(value: boolean) {
    this.isRatesStatus = value;
  }

  @Mutation
  public setCacheRatesStatus(value: boolean) {
    this.isCacheRatesStatus = value;
  }

  @Action
  public _onGetDetail(id: number) {
    this.setLoading(true);
    const presenter = container.resolve(BaseRatesPresenter);
    presenter
      .getDetailRates(id)
      .then((res: RatesDetail) => {
        this.setRatesDetail(res);
        this.setRatesStatus(
          res.rateVersionStatus.toLowerCase() === "draft" ? false : true
        );
        this.setCacheRatesStatus(
          res.rateVersionStatus.toLowerCase() === "draft" ? false : true
        );
        this.setPeriodeStart(new Date(res.rateVersionStartDate));
        this.setPeriodeEnd(new Date(res.rateVersionEndDate));
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public handleError() {
    this.setError(false);
  }

  // Date Picker
  public convertPeriodeStart = new Date();
  public convertPeriodeEnd = new Date();

  @Mutation
  public setPeriodeStart(date: any) {
    this.convertPeriodeStart = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }
  @Mutation
  public setPeriodeEnd(date: any) {
    this.convertPeriodeEnd = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }

  @Mutation
  public setOpenSuccess(value: boolean) {
    this.openSuccess = value;
  }

  @Action
  public _onEdit(params: { id: number; name: string; status: string }) {
    this.setOpenModal(false);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(BaseRatesPresenter);
    presenter
      .editBaseRates(
        params.id,
        new EditBaseRatesApiRequest(params.name, params.status)
      )
      .then(() => {
        this.setOpenSuccess(true);
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Perubahan Gagal !", () =>
            this._onEdit(params)
          )
        );
        this.setOpenSuccess(false);
      })
      .finally(() => {
        this.setOpenModal(false);
        MainAppController.closeLoading();
      });
  }
}

export const BaseRateController = getModule(BaseRatesStore);
