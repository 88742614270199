/* eslint-disable @typescript-eslint/camelcase */
import { ChangeBaseRatesRequestInterface } from "../contracts/BaseRatesRequest";

export class EditBaseRatesApiRequest
  implements ChangeBaseRatesRequestInterface {
  rate_version_name: string;
  rate_version_status: string;

  constructor(rate_version_name: string, rate_version_status: string) {
    this.rate_version_name = rate_version_name;
    this.rate_version_status = rate_version_status;
  }

  public toJSON(): string {
    return JSON.stringify({
      rate_version_name: this.rate_version_name,
      rate_version_status: this.rate_version_status
    });
  }
}
